<template>
  <v-dialog
    v-model="dialog"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="dateValue"
        v-bind="{ 'outlined' : true}"
        :label="label ? label : $t('availability.date', locale)"
        prepend-inner-icon="mdi-calendar-clock"
        readonly
        v-on="on"
        hide-details
      />
    </template>
    <v-date-picker
      v-model="date"
      :locale="locale"
      scrollable
    >
      <v-spacer />
      <v-btn
        @click="handleCancel"
      >
        {{ $t('common.cancel', locale) }}
      </v-btn>
      <v-btn
        color="primary"
        @click="handleSave"
      >
        {{ $t('common.ok', locale) }}
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>
<script>
  export default{
    name: 'DialogDatePicker',
    props: {
      locale: {
        type: String,
        requied: true,
      },
      value: {
        type: [ Date, String],
        default: null
      },
      label: {
        type: String,
        default: null
      },
    },
    data () {
      return {
        dateValue: null,
        date: null,
        dialog: false
      }
    },
    watch: {
      value () {
        this.prepareData()
      },

    },
    mounted () {
      this.prepareData()
    },
    methods: {
      prepareData () {
        const date = typeof this.value === 'string' ? new Date(this.value) : this.value
        this.dateValue = date ? date.toISOString().split('T')[0] : new Date().toISOString().split('T')[0]
        this.date = this.dateValue
      },
      handleCancel () {
        this.date = this.dateValue
        this.dialog = false
      },
      handleSave () {
        this.dateValue = this.date 
        this.$emit('input', new Date(this.date))
        this.dialog = false
      }
    }
  }
</script>
